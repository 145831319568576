import React, { useState } from "react";

import {
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SaveIcon from "@mui/icons-material/Save";

import { updateArticleVarioId } from "../../../../database";
import { useAuth } from "../../../../AuthContext";
import {
  createVarioIdFromNumber,
  getVarioIdFromNumber,
} from "../../../../serverFunctions";
import AlsoVarioSyncAddArticleAlert from "./AlsoVarioSyncAddArticleAlert";

interface Props {
  article: API.DatabaseArticle;
}

const AlsoVarioSyncTableRow: React.FC<Props> = ({ article }) => {
  const auth = useAuth();
  const company = auth.currentDatabaseCompany!;

  const disabled = article.varioId === 0;

  const [varioNumber, setVarioNumber] = useState(article.varioArticleNumber);
  const [showAddArticleAlert, setShowAddArticleAlert] = useState(false);
  const [editMode, setEditMode] = useState(disabled);
  const [waiting, setWaiting] = useState(false);

  const handleSave = () => {
    if (varioNumber === "") {
      updateArticleVarioId(company.id, {
        ...article,
        varioId: 0,
        varioArticleNumber: "",
      });
      return;
    }
    setWaiting(true);
    getVarioIdFromNumber({
      companyId: company.id,
      articleNumber: varioNumber,
    }).then(({ data }: any) => {
      setWaiting(false);
      if (data.success) {
        if (data.id === null) {
          setShowAddArticleAlert(true);
        } else {
          updateArticleVarioId(company.id, {
            ...article,
            varioId: data.id,
            varioArticleNumber: varioNumber,
          });
          setEditMode(false);
        }
      }
    });
  };

  const createArticle = () => {
    setShowAddArticleAlert(false);
    setWaiting(true);
    createVarioIdFromNumber({
      companyId: company.id,
      articleNumber: varioNumber,
      articleName: article.name,
      articleWarengruppeId: company.warengruppeId,
    }).then(({ data }: any) => {
      setWaiting(false);
      if (data.success) {
        updateArticleVarioId(company.id, {
          ...article,
          varioId: data.newId,
          varioArticleNumber: varioNumber,
        });
        setEditMode(false);
      }
    });
  };

  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        background: disabled ? "#F5F5F5" : "default",
      }}
    >
      <TableCell component="th" scope="row">
        <Tooltip title={article.articleNumber}>
          <Typography sx={disabled ? { color: "#777" } : {}} fontSize="small">
            {article.name} ({article.alsoId})
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="right">
        {article.priceNetto
          ? article.priceNetto.toFixed(2).toString().replace(".", ",") + " €"
          : "-"}
      </TableCell>
      <TableCell align="right">
        {editMode ? (
          <TextField
            label="Art. Nr."
            variant="standard"
            value={varioNumber}
            onChange={({ target }) => setVarioNumber(target.value)}
            size="small"
          />
        ) : (
          varioNumber
        )}
      </TableCell>
      <TableCell align="right">
        {editMode ? (
          <IconButton onClick={handleSave} disabled={waiting}>
            <SaveIcon />
          </IconButton>
        ) : (
          <Tooltip title={article.varioId}>
            <IconButton color="primary" onClick={() => setEditMode(true)}>
              <CheckCircleIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
      {showAddArticleAlert && (
        <AlsoVarioSyncAddArticleAlert
          open={showAddArticleAlert}
          handleClose={() => setShowAddArticleAlert(false)}
          articleName={article.name}
          articleNumber={varioNumber}
          action={createArticle}
        />
      )}
    </TableRow>
  );
};

export default AlsoVarioSyncTableRow;
