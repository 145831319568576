
export const germanState = (state: API.moduleState) => {
  switch (state) {
    case "active":
      return "Aktiviert";
    case "available":
      return "Verfügbar";
    case "disabled":
      return "Deaktiviert";
    case "ordered":
      return "Bestellt";
    default:
      return "";
  }
};

export const germanRight = (state: API.userRight) => {
  switch (state) {
    case "admin":
      return "Admistrator";
    case "chef":
      return "Admin";
    case "user":
      return "User";
    default:
      return "";
  }
};

export const germanApi = (api: API.APIS) => {
  switch (api) {
    case "VARIO":
      return "Vario";
    case "VELO":
      return "Velo";
    case "ALSO":
      return "Also";
    case "TEST":
      return "Test";
    default:
      return "";
  }
};