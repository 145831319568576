import {
  doc,
  collection,
  query,
  where,
  onSnapshot,
  setDoc,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  getDocs,
} from "firebase/firestore";
import { Objects } from "./misc/objects";
import { auth, firestore } from "./firebase";

const db = firestore;

export const getCompany = (
  id: string,
  func: (company: API.company) => void
) => {
  const unsubscribe = onSnapshot(doc(db, "companies", id), (doc) => {
    const data = doc.data();
    const customer: API.company = {
      id: doc.id,
      nr: data?.nr ?? "",
      name: data?.name ?? "Neues Unternehmen",
      warengruppeId: data?.warengruppeId ?? "",
    };
    func(customer);
  });

  return unsubscribe;
};

export const getUser = (func: (user: API.user) => void) => {
  const uid = auth.currentUser?.uid || "";
  const unsubscribe = onSnapshot(doc(db, "users", uid), (doc) => {
    const data = doc.data();
    const customer: API.user = {
      id: doc.id,
      companyId: data?.companyId,
      name: data?.name,
      rights: data?.rights,
    };
    func(customer);
  });

  return unsubscribe;
};

export const updateCompany = (company: API.company) => {
  let dbcompany: any = { ...company };
  delete dbcompany.id;
  return setDoc(doc(db, "companies", company.id), dbcompany);
};

export const getCompanyModules = (
  companyId: string,
  func: (modules: API.CompanyModule[]) => void
) => {
  const q = query(collection(db, `companies/${companyId}/modules`));

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const modules: API.CompanyModule[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();

      let api: API.APIS = "ALSO";
      switch (doc.id) {
        case "ALSO":
        case "VARIO":
        case "VELO":
          api = doc.id as API.APIS;
          break;
        default:
          return;
      }

      const staticData = Objects.availableModules.filter(
        (m) => m.id === api
      )[0];

      const tmp: API.CompanyModule = {
        id: api,
        name: staticData.name,
        logo: staticData.logo,
        description: staticData.description,
        state: data.state,
      };
      modules.push(tmp);
    });
    func(modules);
  });

  return unsubscribe;
};

export const updateCompanyModule = (
  companyId: string,
  module: API.CompanyModule
) => {
  return setDoc(doc(db, `companies/${companyId}/modules`, module.id), {
    state: module.state,
  });
};

export const updateUser = (user: API.user) => {
  let dbuser: any = { ...user };
  delete dbuser.id;
  return setDoc(doc(db, "users", user.id), user);
};

export const addAsyncTask = (companyId: string, func: API.AsyncFunction) => {
  const task: API.Task = {
    id: "",
    func,
    type: "async",
  };
  let dbtask: any = task;
  delete dbtask.id;
  dbtask.companyId = companyId;
  dbtask.creationTime = new Date();
  return addDoc(collection(db, "tasks"), dbtask);
};

export const getTasks = (
  companyId: string,
  f: API.AsyncFunction | UserFunctionsTypes.SyncFunction,
  func: (tasks: API.Task[]) => void
) => {
  const q = query(
    collection(db, "tasks"),
    where("companyId", "==", companyId),
    where("func", "==", f),
    orderBy("creationTime", "desc")
  );
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    let tasks: API.Task[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();

      let stages: API.TaskStage[] = [];

      if (data.stages) {
        for (const stage of data.stages) {
          stages = [
            ...stages,
            {
              name: stage.name,
              state: stage.state,
              startTime: stage.startTime ? stage.startTime.toDate() : undefined,
              endTime: stage.endTime ? stage.endTime.toDate() : undefined,
            },
          ];
        }
      }

      tasks = [
        ...tasks,
        {
          id: doc.id,
          type: data.type,
          func: data.func,
          creationTime: data.creationTime
            ? data.creationTime.toDate()
            : undefined,
          stages: stages,
        },
      ];
    });
    func(tasks);
  });

  return unsubscribe;
};

export const getArticles = (
  companyId: string,
  func: (articles: API.DatabaseArticle[]) => void
) => {
  const q = query(
    collection(db, `companies/${companyId}/articles`),
    orderBy("alsoId")
  ); //, where("capital", "==", true)

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const articles: API.DatabaseArticle[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const tmp: API.DatabaseArticle = {
        id: doc.id,
        varioId: data.varioId,
        alsoId: data.alsoId,
        articleNumber: data.articleNumber,
        varioArticleNumber: data.varioArticleNumber,
        name: data.name,
        priceNetto: data.priceNetto ?? null,
      };
      articles.push(tmp);
    });
    func(articles);
  });

  return unsubscribe;
};

export const updateArticleVarioId = async (
  companyId: string,
  article: API.DatabaseArticle
) => {
  let dbArticle: any = { ...article };
  delete dbArticle.id;
  return updateDoc(
    doc(db, `companies/${companyId}/articles`, article.id),
    dbArticle
  );
};

//// ADMIN

// import {
//   getFirestore,
//   doc,
//   getDoc,
//   collection,
//   query,
//   where,
//   onSnapshot,
//   setDoc,
//   orderBy,
//   addDoc,
// } from "firebase/firestore";
// import { Objects } from "./common/objects";
// import { app } from "./firebase";

// const db = getFirestore(app);

// export const getAdminState = async (user: string) => {
//   try {
//     const docRef = doc(db, "users", user);
//     const docSnap = await getDoc(docRef);
//     if (docSnap.exists()) {
//       return docSnap.data().rights === "admin";
//     } else {
//       return false;
//     }
//   } catch (error) {
//     return false;
//   }
// };

export const getCompanies = (func: (customer: API.company[]) => void) => {
  const q = query(collection(db, "companies"));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    let companies: API.company[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      companies = [
        ...companies,
        {
          id: doc.id,
          nr: data.nr ?? "",
          name: data.name ?? "Neues Unternhemen",
          warengruppeId: data.warengruppeId ?? ""
        },
      ];
    });
    func(companies);
  });

  return unsubscribe;
};

// export const getCompany = (
//   uid: string,
//   func: (company: API.company) => void
// ) => {
//   const unsubscribe = onSnapshot(doc(db, "companies", uid), (doc) => {
//     const data = doc.data();
//     const company: API.company = {
//       id: doc.id,
//       nr: data?.nr ?? "",
//       name: data?.name ?? "Neues Unternehmen",
//     };
//     func(company);
//   });

//   return unsubscribe;
// };

// export const updateCompany = (company: API.company) => {
//   let dbcompany: any = { ...company };
//   delete dbcompany.id;
//   dbcustomer.isAdmin = false;
//   return setDoc(doc(db, "companies", company.id), dbcompany);
// };

export const addCompany = () => {
  const company: API.company = {
    id: "",
    nr: "",
    name: "Neues Unternehmen",
  };
  let dbcompany: any = company;
  delete dbcompany.id;
  return addDoc(collection(db, "companies"), dbcompany);
};

export const getUsers = (
  companyId: string,
  func: (users: API.user[]) => void
) => {
  const q = query(collection(db, "users"), where("companyId", "==", companyId));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    let users: API.user[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      users = [
        ...users,
        {
          id: doc.id,
          companyId,
          name: data.name ?? "Unbenannter User",
          rights: data.rights ?? "user",
        },
      ];
    });
    func(users);
  });

  return unsubscribe;
};

// export const updateUser = (user: API.user) => {
//   let dbuser: any = { ...user };
//   delete dbuser.id;
//   dbcustomer.isAdmin = false;
//   return setDoc(doc(db, "users", user.id), dbuser);
// };

export const getAdmins = (func: (users: API.user[]) => void) => {
  const q = query(collection(db, "users"), where("rights", "==", "admin"));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    let users: API.user[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      users = [
        ...users,
        {
          id: doc.id,
          companyId: "",
          name: data.name ?? "",
          rights: "admin",
        },
      ];
    });
    func(users);
  });

  return unsubscribe;
};

export const addCredentials = (companyId: string, api: API.APIS) => {
  const credentials: API.Credentials = {
    id: "",
    url: "",
    username: "",
    password: "",
    prefix: "",
    name: ""
  };
  let dbcredentials: any = credentials;
  delete dbcredentials.id;
  return addDoc(
    collection(db, `companies/${companyId}/modules/${api}/credentials`),
    dbcredentials
  );
};

export const getCredentials = (
  companyId: string,
  api: API.APIS,
  func: (credentials: API.Credentials[]) => void
) => {
  const q = query(
    collection(db, `companies/${companyId}/modules/${api}/credentials`)
  );
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    let credentials: API.Credentials[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      credentials = [
        ...credentials,
        {
          id: doc.id,
          url: data.url ?? "",
          username: data.username ?? "",
          password: data.password ?? "",
          verified: data.verified,
          prefix: api==="VELO" ? (data.prefix ?? "") : undefined,
          name: api==="VELO" ? (data.name ?? "") : undefined
        },
      ];
    });
    func(credentials);
  });

  return unsubscribe;
};

export const updateCredentials = async (
  companyId: string,
  api: API.APIS,
  credentials: API.Credentials
) => {
  let dbCredentials: any = { ...credentials };
  delete dbCredentials.id;
  if (dbCredentials.verified === undefined) delete dbCredentials.verified;
  return updateDoc(
    doc(
      db,
      `companies/${companyId}/modules/${api}/credentials`,
      credentials.id
    ),
    dbCredentials
  );
};

export const deleteCredentials = async (
  companyId: string,
  api: API.APIS,
  credentialsId: string
) => {
  return deleteDoc(
    doc(db, `companies/${companyId}/modules/${api}/credentials`, credentialsId)
  );
};

export const updateVeloVarioIds = async (
  companyId: string,
  article: API.VeloVarioArticle
) => {
  let dbArticle: any = { ...article };
  delete dbArticle.id;

  if (article.id !== "") {
    return updateDoc(
      doc(db, `companies/${companyId}/velo`, article.id),
      dbArticle
    );
  }

  return addDoc(collection(db, `companies/${companyId}/velo`), dbArticle);
};

export const getVarioIdFromVelo = async (
  companyId: string,
  apiId: string,
  veloId: string
) => {
  const q = query(
    collection(db, `companies/${companyId}/velo`),
    where("apiId", "==", apiId),
    where("veloId", "==", veloId)
  );
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) return null;
  const data = querySnapshot.docs[0].data() as API.VeloVarioArticle;
  return { ...data, id: querySnapshot.docs[0].id };
};

export const deleteVeloVarioIds = async (
  companyId: string,
  articleId: string
) => {
  return deleteDoc(doc(db, `companies/${companyId}/velo`, articleId));
};
