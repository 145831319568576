import React, { useEffect, useState } from "react";

// import {
//   Divider,
//   FormControl,
//   InputLabel,
//   MenuItem,
//   Select,
// } from "@mui/material";

import { germanApi } from "../../../misc/misc";
import CredentialInput from "./CredentialInput";
import CustomDialog from "./CustomDialog";
import AddCredentialsButton from "./AddCredentialsButton";
import { useAuth } from "../../../AuthContext";
import {
  deleteCredentials,
  getCredentials,
  updateCredentials,
} from "../../../database";

interface Props {
  module: API.CompanyModule;
  open: boolean;
  close: () => void;
}

const AlsoCredentials: React.FC<Props> = ({ module, open, close }) => {
  // const [marketplaces, setMarketplaces] = useState<API.AlsoMarketplaceInfo[]>(
  //   []
  // );

  const [credentials, setCredentials] = useState<API.Credentials[]>([]);

  const auth = useAuth();
  const company = auth.currentDatabaseCompany!;

  useEffect(() => {
    return getCredentials(company.id, module.id, setCredentials);
  }, [module]);

  const credentialsChangeHandler = (credentials: API.Credentials) => {
    delete credentials.prefix;
    delete credentials.name;
    updateCredentials(company.id, module.id, credentials);
  };

  const credentialsDeleteHandler = (credentialsId: string) => {
    deleteCredentials(company.id, module.id, credentialsId);
  };

  return (
    <CustomDialog open={open} close={close} apiName={germanApi(module.id)}>
      {credentials[0] ? (
        <CredentialInput
          credentials={credentials[0]}
          onChange={credentialsChangeHandler}
          onDelete={credentialsDeleteHandler}
        />
      ) : (
        <AddCredentialsButton api={module.id} />
      )}

      {/* <Divider />
      <FormControl variant="standard" fullWidth>
        <InputLabel id="default-marketplace-select">Marketplace</InputLabel>
        <Select
          labelId="default-marketplace-select"
          value={""}
          onChange={() => {}}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {marketplaces.map((m) => (
            <MenuItem key={m.Id} value={m.Id}>
              {m.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
    </CustomDialog>
  );
};

export default AlsoCredentials;
