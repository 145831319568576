import React, { useState } from "react";

import { Button } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import LogoutIcon from "@mui/icons-material/Logout";
import AdminManagement from "./AdminManagement";
import CompanySelect from "./CompanySelect";

function AdminPanel() {
  const navigate = useNavigate();
  const auth = useAuth();

  const [showAdminManagement, setShowAdminManagement] = useState(false);

  if (auth.currentDatabaseUser?.rights !== "admin") return null;

  return (
    <>
      <CompanySelect/>
      <AdminManagement
        open={showAdminManagement}
        close={() => setShowAdminManagement(false)}
      />
      <Button
        variant="contained"
        sx={{ alignSelf: "center", mr: 2 }}
        onClick={() => setShowAdminManagement(true)}
      >
        Admins Verwaltung
      </Button>
      <Button
        variant="contained"
        startIcon={<LogoutIcon />}
        onClick={() => auth.signOut().finally(() => navigate("/login"))}
        sx={{ alignSelf: "center", mr: 2 }}
        color="error"
      >
        Logout
      </Button>
    </>
  );
}

export default AdminPanel;
