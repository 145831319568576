import React from "react";

import { Button } from "@mui/material";
import { useAuth } from "../../../AuthContext";

import { addCredentials } from "../../../database";

interface Props {
  api: API.APIS;
}

const AddCredentialsButton: React.FC<Props> = ({ api }) => {
  const auth = useAuth();
  const company = auth.currentDatabaseCompany!;

  return (
    <Button
      variant="outlined"
      fullWidth
      onClick={() => {
        addCredentials(company.id, api);
      }}
    >
      Zugangsdaten hinzufügen
    </Button>
  );
};

export default AddCredentialsButton;
