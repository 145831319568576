import React, { useContext, useState, useEffect } from "react";

import {
  User,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut as firebaseSignOut,
} from "firebase/auth";
import { auth } from "./firebase";
import { getCompany, getUser } from "./database";

interface IAuthContext {
  currentUser: User | null;
  currentDatabaseUser: API.user | null;
  currentDatabaseCompany: API.company | null;
  developer: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  selectCompany: (id: string) => void;
}

const defaultValue: IAuthContext = {
  currentUser: null,
  currentDatabaseUser: null,
  currentDatabaseCompany: null,
  developer: false,
  signIn: (email: string, password: string) => Promise.reject(),
  signOut: () => Promise.reject(),
  selectCompany: (id: string) => {},
};

const AuthContext = React.createContext<IAuthContext>(defaultValue);

export function useAuth() {
  return useContext(AuthContext);
}

declare global {
  interface Window {
    setDeveloper: (developer: boolean) => void;
  }
}

export const AuthProvider = ({ children }: { children?: React.ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [currentDatabaseUser, setCurrentDatabaseUser] =
    useState<API.user | null>(null);
  const [currentDatabaseCompany, setCurrentDatabaseCompany] =
    useState<API.company | null>(null);
  const [selectedCompany, setSelectedCompany] = useState("");

  const [developer, setDeveloper] = useState(false);

  window.setDeveloper = (developer: boolean) => {
    setDeveloper(developer);
  };

  useEffect(() => {
    let getUserUnsubscribe: any;
    const onAuthStateChangedUnsubscribe = onAuthStateChanged(auth, (user) => {

      if (getUserUnsubscribe) {
        getUserUnsubscribe();
        getUserUnsubscribe = null;
      }

      setCurrentUser(user);
      if (user) {
        getUserUnsubscribe = getUser(setCurrentDatabaseUser);
      } else {
        setCurrentDatabaseUser(null);
        setSelectedCompany("");
      }
    });
    return () => {
      onAuthStateChangedUnsubscribe();
      if (getUserUnsubscribe) getUserUnsubscribe();
    };
  }, []);

  useEffect(() => {
    let getCompanyUnsubscribe: any;
    if (currentDatabaseUser && currentDatabaseUser.companyId) {
      getCompanyUnsubscribe = getCompany(
        currentDatabaseUser.companyId,
        setCurrentDatabaseCompany
      );
    }
    if (getCompanyUnsubscribe)
      return () => {
        getCompanyUnsubscribe();
        getCompanyUnsubscribe = null;
        setCurrentDatabaseCompany(null);
      };
  }, [currentDatabaseUser]);

  useEffect(()=>{
    if(selectedCompany !== "")
      return getCompany(selectedCompany, setCurrentDatabaseCompany);
    else{
      setCurrentDatabaseCompany(null);
    }
  },[selectedCompany])

  const signIn = (email: string, password: string) => {
    return new Promise<void>((resolve, reject) => {
      signInWithEmailAndPassword(auth, email, password)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  };

  const signOut = () => {
    return firebaseSignOut(auth);
  };

  const selectCompany = (id: string) => {
    setSelectedCompany(id);
  }

  const value: IAuthContext = {
    currentUser,
    currentDatabaseUser,
    currentDatabaseCompany,
    developer,
    signIn,
    signOut,
    selectCompany,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
