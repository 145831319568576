import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../AuthContext";
import {
  addAsyncTask,
  getArticles,
  getCredentials,
  updateArticleVarioId,
} from "../../../../database";

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import VeloVarioSyncTableRow from "./VeloVarioSyncTableRow";
import {
  veloCreateTextSearchRequest,
  veloSearchResultRequest,
} from "../../../../serverFunctions";

import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import SaveIcon from "@mui/icons-material/Save";

export type veloData = {
  id: string;
  manufacturerId: string;
  name: string;
  price: number;
  availability: string;
  image: string;
  ean: string;
  sellPrice: number;
  attributes: {
    name: string;
    value: string;
  }[];
};

const VeloVarioSync: React.FC<{}> = () => {
  const auth = useAuth();
  const company = auth.currentDatabaseCompany!;

  const [filter, setFilter] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [credentials, setCredentials] = useState<API.Credentials[]>([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState<
    string | null
  >(null);
  const [transactionId, setTransactionId] = useState<string | null>();
  const [totalCount, setTotalCount] = useState(0);
  const [veloData, setVeloData] = useState<veloData[]>([]);
  const [noResults, setNoResults] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return getCredentials(company.id, "VELO", setCredentials);
  }, []);

  useEffect(() => {
    if (!transactionId) return;
    const cred = credentials.filter((c) => c.id === selectedManufacturer)[0];
    veloSearchResultRequest({
      credentials: cred,
      transactionId,
      count: rowsPerPage < totalCount ? rowsPerPage : totalCount,
      startIndex: page * rowsPerPage,
    }).then(({ data }: any) => {
      setLoading(false);
      if (!data.success) return;
      setVeloData(
        data.items.map((item: any) => ({
          id: item.sellerId ?? "",
          manufacturerId: item.sellerId ?? "",
          name: item.description ?? "",
          price: item.price ?? 0,
          availability: item.availability ?? "",
          image: item.picture ?? "",
          varioId: 0,
          ean: item.ean ? item.ean.toString() : "",
          sellPrice: item.sellPrice ?? 0,
          attributes: item.attributes ?? []
        }))
      );
    });
  }, [page, rowsPerPage, transactionId, totalCount]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const handleSearch = () => {
    const cred = credentials.filter((c) => c.id === selectedManufacturer)[0];

    setPage(0);
    setVeloData([]);
    setTransactionId(null);
    setTotalCount(0);
    setLoading(true);

    veloCreateTextSearchRequest({
      credentials: cred,
      searchString: filter,
    }).then(({ data }: any) => {
      if (!data.success) {
        setLoading(false);
        return;
      }
      if (data.totalCount === 0) {
        setNoResults(true);
        setLoading(false);
        return;
      }
      setNoResults(false);
      setTotalCount(data.totalCount);
      setTransactionId(data.transactionId);
    });
  };

  const buttonDisabled = filter === "" || selectedManufacturer === null;

  const cred = credentials.filter((c) => c.id === selectedManufacturer)[0];

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (buttonDisabled || loading) return;
          handleSearch();
        }}
      >
        <Stack direction="row" spacing={1} m={1}>
          <FormControl sx={{ minWidth: "250px" }} variant="standard">
            <InputLabel id="manufacturer-select-label">Lieferant</InputLabel>
            <Select
              labelId="manufacturer-select-label"
              id="manufacturer-select"
              value={selectedManufacturer ?? ""}
              label="Lieferant"
              onChange={(e) => setSelectedManufacturer(e.target.value)}
            >
              {credentials.map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Filter"
            value={filter}
            onChange={handleChangeFilter}
            fullWidth
            variant="standard"
          />

          <Button disabled={buttonDisabled || loading} type="submit">
            Suchen
          </Button>
        </Stack>
      </form>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Foto</TableCell>
              <TableCell>Hersteller-ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Lager</TableCell>
              <TableCell>Preis</TableCell>
              <TableCell>Vario</TableCell>
              <TableCell align="center">
                <SaveIcon />
              </TableCell>
              <TableCell align="center">
                <ShoppingCartCheckoutIcon />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {veloData.map((a) => (
              <VeloVarioSyncTableRow
                key={a.id}
                article={a}
                selectedManufacturer={selectedManufacturer ?? ""}
                credentials={cred}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {noResults && <Alert severity="info">Keine Ergbnisse gefunden!</Alert>}
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default VeloVarioSync;
