import React, { useEffect, useState } from "react";

import {
  Button,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material";

import ListIcon from "@mui/icons-material/List";
import TaskExecution from "./TaskExecution"
import { getTasks } from "../../database";
import { useAuth } from "../../AuthContext";

export type TaskElement = {
  func: (companyId: string) => void;
  id: UserFunctionsTypes.SyncFunction | API.AsyncFunction;
  name: string;
  description: string;
  requiredAPI: API.APIS[];
};

interface Props {
  task: TaskElement;
  verified: boolean;
}


const Task: React.FC<Props> = ({ task, verified }) => {
  const [open, setOpen] = useState(false);

  const [tasks, setTasks] = useState<API.Task[]>([]);

  const auth = useAuth();
  const companyId = auth.currentDatabaseCompany?.id ?? "";

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const unsubscribe = getTasks(companyId, task.id, setTasks);
    return () => unsubscribe();
  }, [task.id]);

  return (
    <>
      <ListItem
        disablePadding
        secondaryAction={
          <Button variant="outlined" onClick={() => task.func(companyId)} disabled={!verified}>
            {task.name}
          </Button>
        }
      >
        <ListItemButton onClick={handleClick}>
          <Stack direction="row" spacing={2} alignItems="center">
            <ListIcon />
            <ListItemText primary={task.name} secondary={task.description} />
          </Stack>
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {tasks.map((t) => (
            <TaskExecution key={t.id} name={task.name} task={t} />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default Task;
