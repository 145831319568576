export namespace Objects {
  export const availableModules: API.module[] = [
    {
      id: "ALSO",
      description: "Also ist...",
      logo: "https://www.also.com/favicon.ico",
      name: "ALSO",
    },
    {
      id: "VARIO",
      description: "Vario ist...",
      logo: "https://www.vario-software.de/wp-content/themes/vario/img/favicon.ico",
      name: "Vario",
    },
    {
      id: "VELO",
      description: "Velo ist...",
      logo: "https://velo-api.de/wp-content/uploads/2022/04/cropped-Logo_VeloApi_Icon_ROT-32x32.png",
      name: "Velo",
    },
  ];  
}

