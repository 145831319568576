import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface Props {
  open: boolean;
  close: () => void;
  apiName: string;
}

const CustomDialog: React.FC<Props> = ({
  open,
  close,
  apiName,
  children
}) => {

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>{apiName}-Zugangsdaten</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Hier können die {apiName}-Zugangsdaten gespeichert und
          überprüft werden. Die API wird erst zur Verwendung freigeschaltet,
          wenn die Überprüfung erfolgreich war.
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Schließen</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
