import {
  Chip,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
} from "@mui/material";
import React from "react";

import AssignmentIcon from "@mui/icons-material/Assignment";
import SuccessIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import ErrorIcon from "@mui/icons-material/Error";

interface Props {
  name: string;
  task: API.Task;
}

const TaskExecution: React.FC<Props> = ({ name, task }) => {
  let taskNum: number = 0;
  let taskNumSuccess: number = 0;
  if (task.stages) {
    taskNum = task.stages.length;
    taskNumSuccess = task.stages.filter((s) => s.state === "success").length;
  }

  return (
    <ListItem
      sx={{ pl: 4 }}
      secondaryAction={
        <Stack direction="row" spacing={1}>
          {task.stages?.map((stage) => getChip(stage))}
        </Stack>
      }
    >
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      {taskNum ? (
        <ListItemText
          primary={task.creationTime ? task.creationTime.toLocaleString() : ""}
          secondary={`${taskNumSuccess}/${taskNum}`}
        />
      ) : (
        <ListItemText primary={task.creationTime ? task.creationTime.toLocaleString() : ""} />
      )}
    </ListItem>
  );
};

export default TaskExecution;

const getChip = (stage: API.TaskStage) => {
  switch (stage.state) {
    case "success":
      return (
        <Tooltip
          key={stage.name}
          title={
            stage.endTime
              ? `Erfolgreich abgeschlossen am ${stage.endTime.toLocaleDateString()} um ${stage.endTime.toLocaleTimeString()}.`
              : "Erfolgreich abgeschlossen."
          }
          arrow
        >
          <Chip
            color="primary"
            variant="outlined"
            icon={<SuccessIcon />}
            label={stage.name}
          />
        </Tooltip>
      );
    case "pending":
      return (
        <Tooltip key={stage.name} title="ausstehend" arrow>
          <Chip
            color="warning"
            variant="outlined"
            icon={<PendingIcon />}
            label={stage.name}
          />
        </Tooltip>
      );
    case "error":
      return (
        <Tooltip key={stage.name} title="fehlgeschlagen" arrow>
          <Chip
            color="error"
            variant="outlined"
            icon={<ErrorIcon />}
            label={stage.name}
          />
        </Tooltip>
      );
  }
};
