import React from "react";

import { Box, Container, Paper} from "@mui/material";

export const View: React.FC<{}> = ({ children }) => {
  return (
    <Container sx={{ marginTop: "80px", background: "#EEEFE" }} maxWidth = {false}>
      <Box sx={{ background: "#EEEFE" }} >
        <Paper sx={{ m: 2, p: 2, background: "#EEE" }}>
          {children}
        </Paper>
      </Box>
    </Container>
  );
};

export default View;
