import {
  Button,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getUsers, updateUser } from "../../database";

import UserRightIcon from "./UserRightIcon";
import { germanRight } from "../../misc/misc";

import * as functions from "../../serverFunctions";
import AddUserDialog from "./AddUserDialog";

import PlusIcon from "@mui/icons-material/Add";
import Confirmation from "./Confirmation";
import { useAuth } from "../../AuthContext";

const UserList = () => {
  const [users, setUsers] = useState<API.user[]>([]);

  const [showAddUser, setShowAddUser] = useState(false);
  const [addUserError, setAddUserError] = useState("");
  const [disableAddUser, setDisableAddUser] = useState(false);

  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const auth = useAuth();
  const company = auth.currentDatabaseCompany!;

  useEffect(() => {
    if (company.id) {
      const unsubscribe = getUsers(company.id, setUsers);
      return unsubscribe;
    }
  }, [company.id]);

  const updateUserRights = (user: API.user, rights: API.userRight) => {
    updateUser({
      ...user,
      rights,
    });
  };

  const addUser = (email: string, password: string) => {
    setDisableAddUser(true);
    const credentials: functions.CustomerCredentials = {
      email,
      password,
    };
    functions
      .addUser({ credentials, companyId: company.id, rights: "user" })
      .then(() => {
        setShowAddUser(false);
        setDisableAddUser(false);
      })
      .catch((error) => {
        setAddUserError(`Error: ${error.message}`);
      });
  };

  const deleteUser = (uid: string) => {
    setButtonsDisabled(true);
    functions.deleteUser(uid).finally(()=>{
      setButtonsDisabled(false);
    })
  }

  return (
    <Paper>
      <List
        sx={{ mt: 1 }}
        subheader={
          <ListSubheader sx={{ background: "transparent" }}>
            Ansprechpartner / User
          </ListSubheader>
        }
      >
        {users.map((user) => (
          <ListItem
            key={user.id}
            secondaryAction={
              <>
                <UserRightIcon
                  right="user"
                  currentRight={user.rights}
                  setRight={(rights) => updateUserRights(user, rights)}
                />
                <UserRightIcon
                  right="chef"
                  currentRight={user.rights}
                  setRight={(rights) => updateUserRights(user, rights)}
                />
                <Confirmation action={()=>deleteUser(user.id)} buttonDisabled={buttonsDisabled}></Confirmation>
              </>
            }
          >
            <ListItemText
              primary={user.name}
              secondary={germanRight(user.rights)}
            />
          </ListItem>
        ))}
      </List>
      <Button
        variant="contained"
        startIcon={<PlusIcon />}
        onClick={() => setShowAddUser(true)}
      >
        User hinzufügen
      </Button>
      <AddUserDialog
        open={showAddUser}
        action={addUser}
        abort={() => !disableAddUser && setShowAddUser(false)}
        error={addUserError}
        disabled={disableAddUser}
      />
    </Paper>
  );
};

export default UserList;
