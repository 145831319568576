import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#84bf2c',
      contrastText: "#ffffff"
    },
    secondary: {
      main: '#486818',
    },
  },
});

export default theme;