import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface Props {
  open: boolean;
  handleClose: () => void;
  articleName: string;
  articleNumber: string;
  action: () => void;
}

const AlsoVarioSyncAddArticleAlert: React.FC<Props> = ({
  open,
  handleClose,
  articleName,
  articleNumber,
  action,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Artikel in VARIO erstellen?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Soll der Artikel "{articleName}" mit der Artikelnummer "{articleNumber}" erstellt werden?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Nein</Button>
        <Button onClick={action} autoFocus>
          Ja
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlsoVarioSyncAddArticleAlert;
