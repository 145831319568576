import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";

import {
  deleteVeloVarioIds,
  getVarioIdFromVelo,
  updateVeloVarioIds,
} from "../../../../database";
import { useAuth } from "../../../../AuthContext";
import {
  createVeloVarioIdFromNumber,
  getVarioIdFromNumber,
  veloOrder,
} from "../../../../serverFunctions";
import { veloData } from "./VeloVarioSync";
import VeloVarioSyncAddArticleAlert from "./VeloVarioSyncAddArticleAlert";

const truncate = (str: string, n: number) => {
  return str.length > n ? str.slice(0, n - 1) + "…" : str;
};

interface Props {
  article: veloData;
  selectedManufacturer: string;
  credentials: API.Credentials;
}

const VeloVarioSyncTableRow: React.FC<Props> = ({
  article,
  selectedManufacturer,
  credentials,
}) => {
  const auth = useAuth();
  const company = auth.currentDatabaseCompany!;

  const [veloVarioIds, setVeloVarioIds] = useState<API.VeloVarioArticle | null>(
    null
  );

  const [varioNumber, setVarioNumber] = useState(credentials.prefix + article.manufacturerId);
  const [showAddArticleAlert, setShowAddArticleAlert] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [waiting, setWaiting] = useState(false);
  const [waitingForOrder, setWaitingForOrder] = useState(false);

  const refresh = useCallback(() => {
    getVarioIdFromVelo(
      company.id,
      selectedManufacturer,
      article.manufacturerId
    ).then((data) => {
      if (data) {
        setVeloVarioIds(data);
        setEditMode(false);
      }
    });
  }, [article, selectedManufacturer, company.id]);

  useEffect(() => {
    refresh();
  }, [article, selectedManufacturer, refresh]);

  const handleSave = () => {
    if (varioNumber === "") {
      if (veloVarioIds?.id)
        deleteVeloVarioIds(company.id, veloVarioIds.id).then(() => refresh());
      return;
    }
    setWaiting(true);
    getVarioIdFromNumber({
      companyId: company.id,
      articleNumber: varioNumber,
    }).then(({ data }: any) => {
      setWaiting(false);
      if (data.success) {
        if (data.id === null) {
          setShowAddArticleAlert(true);
        } else {
          updateVeloVarioIds(company.id, {
            id: veloVarioIds?.id ?? "",
            apiId: selectedManufacturer,
            varioId: data.id,
            veloId: article.manufacturerId,
          }).then(() => refresh());
          setEditMode(false);
        }
      }
    });
  };

  const createArticle = () => {
    setShowAddArticleAlert(false);
    setWaiting(true);
    createVeloVarioIdFromNumber({
      companyId: company.id,
      articleNumber: varioNumber,
      articleName: article.name,
      articlePrice: article.price,
      articleImage: article.image,
      articleEan: article.ean,
      articleWarengruppeId: company.warengruppeId,
      articleSellPrice: article.sellPrice,
      articleAttributes: article.attributes
    }).then(({ data }: any) => {
      setWaiting(false);
      if (data.success) {
        updateVeloVarioIds(company.id, {
          id: veloVarioIds?.id ?? "",
          apiId: selectedManufacturer,
          varioId: data.newId,
          veloId: article.manufacturerId,
        }).then(() => refresh());
        setEditMode(false);
      }
    });
  };

  const handleOrder = () => {
    setWaitingForOrder(true);
    document.body.style.cursor = "progress";

    veloOrder({
      credentialsId: credentials.id,
      articleId: article.id,
      companyId: company.id,
    }).then(({ data }: any) => {
      if (!data.success) alert("Bestellung fehlgeschlagen!")
      else alert(article.name + " erfolgreich bestellt!")
      setWaitingForOrder(false);
      document.body.style.cursor = "";

    });
  };

  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        background: veloVarioIds === null ? "#F5F5F5" : "default",
      }}
    >
      <TableCell sx={{ p: 1,width: 100 }}>
        {article.image && (
          <img
            src={article.image}
            alt={"picture of " + article.name}
            style={{ maxWidth: "70px", maxHeight: "70px" }}
          />
        )}
      </TableCell>
      <TableCell sx ={{width:100}}>{article.manufacturerId}</TableCell>
      <TableCell>
        <Tooltip title={article.name}>
          <Typography fontSize={"small"}>
            {truncate(article.name, 30)}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell >
        {article.availability === "available" ? <CheckIcon /> : <CloseIcon />}
      </TableCell>
      <TableCell size="small" >
        {article.price.toFixed(2).toString().replace(".", ",") + " €"}
      </TableCell>
      <TableCell width={100}>
        {editMode ? (
          <TextField
            label="Art. Nr."
            variant="standard"
            value={varioNumber}
            onChange={({ target }) => setVarioNumber(target.value)}
            size="small"
          />
        ): (<>{varioNumber}</>)}
      </TableCell>
      <TableCell align="center">
        {editMode ? (
          <IconButton onClick={handleSave} disabled={waiting} size="small">
            <SaveIcon />
          </IconButton>
        ) : (
          <Tooltip title={veloVarioIds?.varioId ?? ""}>
            <IconButton color="primary" onClick={() => setEditMode(true)}>
              <CheckCircleIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
      <TableCell align="center">
        <IconButton
          onClick={handleOrder}
          size="small"
          disabled={veloVarioIds === null || article.availability !== "available" || waitingForOrder}
        >
          <ShoppingCartCheckoutIcon />
        </IconButton>
      </TableCell>
      {showAddArticleAlert && (
        <VeloVarioSyncAddArticleAlert
          open={showAddArticleAlert}
          handleClose={() => setShowAddArticleAlert(false)}
          articleName={article.name}
          articleNumber={varioNumber}
          action={createArticle}
        />
      )}
    </TableRow>
  );
};

export default VeloVarioSyncTableRow;
