import React from "react";

import ChefIcon from "@mui/icons-material/BusinessCenter"
import UserIcon from "@mui/icons-material/SupportAgent"

import { IconButton, Tooltip } from "@mui/material";
import { germanRight } from "../../misc/misc";

interface Props {
  right: API.userRight;
  currentRight: API.userRight;
  setRight: (right: API.userRight) => void;
  end?: boolean;
}

const UserRightIcon: React.FC<Props> = (props) => {
  const active = props.right === props.currentRight;

  const getIcon = (right: API.userRight) => {
    switch (right) {
      case "chef":
        return (
          <ChefIcon
            sx={{ fontSize: "inherit" }}
            color={active ? "primary" : undefined}
          />
        );
      case "user":
        return (
          <UserIcon
            sx={{ fontSize: "inherit" }}
            color={active ? "primary" : undefined}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Tooltip title={germanRight(props.right)}>
      <span>
        <IconButton
          size="small"
          edge={props.end ? "end" : undefined}
          onClick={() => props.setRight(props.right)}
          disabled={active}
        >
          {getIcon(props.right)}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default UserRightIcon;

