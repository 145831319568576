import {
  List,
  ListSubheader,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../AuthContext";
import { addAsyncTask, getCompanyModules } from "../../database";

import Task, { TaskElement } from "./Task";

const elements: TaskElement[] = [
  {
    func: (companyId) => {
      addAsyncTask(companyId, "asyncAlsoProductDownload")
        .then(() => console.log("success"))
        .catch((e) => console.error(e.message));
    },
    id: "asyncAlsoProductDownload",
    name: "Also -> DB",
    description:
      "Produkte von der ALSO Cloud abfragen und in die Datenbank speichern.",
    requiredAPI: ["ALSO"],
  },
  {
    func: (companyId) => {
      addAsyncTask(companyId, "asyncVarioProductUpload")
        .then(() => console.log("success"))
        .catch((e) => console.error(e.message));
    },
    id: "asyncVarioProductUpload",
    name: "DB -> Vario",
    description: "Produkte von der Datenbank abfragen und in Vario speichern.",
    requiredAPI: ["VARIO"],
  },
];


const ApiWrapper = () => {
  const [companyModules, setCompanyModules] = useState<API.CompanyModule[]>([]);

  const auth = useAuth();
  const company = auth.currentDatabaseCompany!;

  useEffect(() => {
    return getCompanyModules(company.id, setCompanyModules);
  }, [company.id]);

  const isVerified = (api: API.APIS) => {
    const module = companyModules.filter(m=>m.id === api)[0];
    return true;//module && module.verified === true;
  };

  const isVerifiedMultiple = (apis: API.APIS[]) => {
    console.log(companyModules)
    for(const api of apis){
      if(!isVerified(api)) return false
    }
    return true;
  };

  return (
    <Paper>
        <List sx={{ pt: 1 }} subheader={<ListSubheader>Tasks</ListSubheader>}>
          {elements.map((element) => (
            <Task
              key={element.id}
              task={element}
              verified={isVerifiedMultiple(element.requiredAPI)}
            />
          ))}
        </List>
    </Paper>
  );
};

export default ApiWrapper;
