import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Alert, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import * as functions from "../serverFunctions";
import DeleteIcon from "@mui/icons-material/Delete";
import { getAdmins } from "../database";

interface Props {
  open: boolean;
  close: () => void;
}

const AddUserDialog: React.FC<Props> = ({
  open,
  close,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (open) {
      setPassword(Math.random().toString(36).substr(2, 8));
      setEmail("");
    }
  }, [open]);

  const onSubmit = () => {
    setDisabled(true);
    const credentials: functions.CustomerCredentials = {
      email,
      password,
    };
    functions
      .addUser({ credentials, rights: "admin" })
      .then(() => {
        close();
        setDisabled(false);
      })
      .catch((error) => {
        setError(`Error: ${error.message}`);
      });
  };

  const [admins, setAdmin] = useState<API.user[]>([]);

  useEffect(() => {
    return getAdmins(setAdmin);
  }, []);

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Admin löschen</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Verwaltung von Admins!
        </DialogContentText>
        
        <List dense={true}>
              {admins.map(admin=>(
                <ListItem
                  key={admin.id}
                  secondaryAction={
                    <IconButton onClick={()=>functions.deleteUser(admin.id)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={admin.name}
                    secondary={admin.id}
                  />
                </ListItem>
              ))}
            </List>
        <Typography variant="h6" component="h2" sx={{mt:2}}>Admin anlegen</Typography>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <TextField
            autoFocus
            margin="dense"
            label="E-Mail-Addresse"
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={disabled}
          />
          <TextField
            margin="dense"
            label="Passwort"
            type="text"
            fullWidth
            variant="standard"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={disabled}
          />
        </form>

        {error && <Alert severity="error">{error}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button onClick={close} disabled={disabled}>
          abbrechen
        </Button>
        <Button onClick={onSubmit} disabled={disabled}>
          erstellen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserDialog;
