import { httpsCallable } from 'firebase/functions';
import { functions } from './firebase';

export const changeModuleState = httpsCallable(functions, 'changeModuleState');
export const validateCredentials = httpsCallable(functions, 'validateCredentials');
export const getVarioIdFromNumber = httpsCallable(functions, 'getVarioIdFromNumber');
export const createVarioIdFromNumber = httpsCallable(functions, 'createVarioIdFromNumber');
export const createVeloVarioIdFromNumber = httpsCallable(functions, 'createVeloVarioIdFromNumber');
export const veloCreateTextSearchRequest = httpsCallable(functions, 'veloCreateTextSearchRequest');
export const veloSearchResultRequest = httpsCallable(functions, 'veloSearchResultRequest');
export const veloOrder = httpsCallable(functions, 'veloOrder');


// Admin
export type CustomerCredentials = AdminFunctionsTypes.Credentials;
export const addUser = httpsCallable(functions, 'addUser');
export const deleteUser = httpsCallable(functions, 'deleteUser');
