import React from "react";

import DisabledIcon from "@mui/icons-material/DisabledByDefault";
import AvailableIcon from "@mui/icons-material/Store";
import ActiveIcon from "@mui/icons-material/Check";
import OrderedIcon from "@mui/icons-material/ShoppingCart";

import { IconButton, Tooltip } from "@mui/material";
import { germanState } from "../../misc/misc";

interface Props {
  state: API.moduleState;
  currentState: API.moduleState;
  setState: (state: API.moduleState) => void;
  end?: boolean;
}

const ModuleStateIcon: React.FC<Props> = (props) => {
  const active = props.state === props.currentState;

  const getIcon = (state: API.moduleState) => {
    switch (state) {
      case "active":
        return (
          <ActiveIcon
            sx={{ fontSize: "inherit" }}
            color={active ? "primary" : undefined}
          />
        );
      case "available":
        return (
          <AvailableIcon
            sx={{ fontSize: "inherit" }}
            color={active ? "primary" : undefined}
          />
        );
      case "disabled":
        return (
          <DisabledIcon
            sx={{ fontSize: "inherit" }}
            color={active ? "error" : undefined}
          />
        );
      case "ordered":
        return (
          <OrderedIcon
            sx={{ fontSize: "inherit" }}
            color={active ? "warning" : undefined}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Tooltip title={germanState(props.state)}>
      <span>
        <IconButton
          size="small"
          edge={props.end ? "end" : undefined}
          onClick={() => props.setState(props.state)}
          disabled={active}
        >
          {getIcon(props.state)}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default ModuleStateIcon;

