import React from "react";
import { useAuth } from "../../AuthContext";
import MenuView from "../../MenuView";
import ApiWrapper from "./ApiWrapper";

function Logs() {
  const auth = useAuth();

  if(!auth.developer) return null;

  return (
    <MenuView>
      <ApiWrapper />
    </MenuView>
  );
}

export default Logs;
