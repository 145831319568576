import { Button, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../AuthContext";
import { updateCompany } from "../../database";
import MenuView from "../../MenuView";

import SaveIcon from "@mui/icons-material/Save";
import UserList from "./Userlist";

function Settings() {
  const auth = useAuth();
  const company = auth.currentDatabaseCompany!;

  const [companyName, setCompanyName] = useState("");
  const [companyNr, setCompanyNr] = useState("");
  const [warengruppeId, setWarengruppeId] = useState<number>();

  useEffect(() => {
    setCompanyName(company.name);
    setCompanyNr(company.nr);
    if(company.warengruppeId !== undefined)
      setWarengruppeId(company.warengruppeId);
  }, [company]);

  return (
    <MenuView>
      <Stack spacing={2}>
        <TextField
          size="small"
          label="Unternehmen"
          value={companyName}
          variant="filled"
          onChange={(e) => setCompanyName(e.target.value)}
        />
        <TextField
          size="small"
          label="Kundennummer"
          value={companyNr}
          variant="filled"
          onChange={(e) => setCompanyNr(e.target.value)}
        />
        <TextField
          size="small"
          label="Vario-Warengruppen-ID"
          value={warengruppeId}
          variant="filled"
          onChange={(e) => {
            const id = Number.parseInt(e.target.value);
            if(!isNaN(id))
              setWarengruppeId(id)
          }}
        />
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={() => updateCompany({ ...company, name: companyName, nr: companyNr, warengruppeId: warengruppeId ?? undefined })}
        >
          Speichern
        </Button>
      </Stack>
      <UserList/>
    </MenuView>
  );
}

export default Settings;
