import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import LoginIcon from "@mui/icons-material/Login";
import Typography from "@mui/material/Typography";

import Logo from "./misc/images/logo.png";

import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { Alert } from "@mui/material";

function Login() {
  const navigate = useNavigate();
  const auth = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const login = () => {
    auth
      .signIn(email, password)
      .then(() => {
        navigate("/");
      })
      .catch(() => {
        setError(true);
      });
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs="auto">
        <Paper>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              login();
            }}
          >
            <Stack padding={2} spacing={2} width={350}>
              <img src={Logo} alt="QMEDIA" style={{ margin: 10 }} />
              <TextField
                label="E-Mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                label="Passwort"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {error && (
                <Alert severity="error">E-Mail oder Passwort falsch!</Alert>
              )}
              <Button
                variant="contained"
                startIcon={<LoginIcon />}
                type="submit"
              >
                Login
              </Button>
              <Typography
              variant="h1"
              sx={{
                fontSize: "0.8rem",
                color: "primary.secondary",
                margin: "5px",
                display: "inline"
              }}
            >
              Version: 1.0.2
            </Typography>
            </Stack>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Login;
