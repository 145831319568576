import React, { ReactElement } from "react";
import {
  ListItemText,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Button,
  Stack,
  Paper,
  Drawer,
  Box
} from "@mui/material";

import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import ApiIcon from "@mui/icons-material/Api";
import SettingsIcon from "@mui/icons-material/Settings";
import StoreIcon from "@mui/icons-material/Store";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import ListIcon from '@mui/icons-material/List';
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";


interface SidebarProps {
  open: boolean;
  onClose: () => void;
}

type menuItem = {
  name: string;
  path: string;
  icon: ReactElement;
  rights: API.userRight[];
  devOnly?: boolean;
};

const menuItems: menuItem[] = [
  {
    name: "Home",
    path: "/",
    icon: <HomeIcon />,
    rights: ["user", "chef", "admin"],
  },
  {
    name: "APIs",
    path: "/apis",
    icon: <ApiIcon />,
    rights: ["user", "chef", "admin"],
  },
  {
    name: "Store",
    path: "/store",
    icon: <StoreIcon />,
    rights: ["chef", "admin"],
  },
  {
    name: "Einstellungen",
    path: "/settings",
    icon: <SettingsIcon />,
    rights: ["chef", "admin"],
  },
  {
    name: "Logs",
    path: "/logs",
    icon: <ListIcon />,
    rights: ["chef", "admin"],
    devOnly: true,
  },
];


const Sidebar: React.FC<SidebarProps> = ({ open, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  

  return (
   <Box
        sx={{
          flexShrink: 0,
          margin: "10px",
          marginTop: "100px",
          marginLeft: "20px",
          backgroundColor: "white",
          height: "fit-content",
          padding: "10px",
          border: "1px solid #e0e0e0",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
        }}>
      <Box>
        <List disablePadding>
          {auth.currentDatabaseUser !== null &&
            menuItems
              .filter((item) =>
                item.rights.includes(auth.currentDatabaseUser!.rights)
              )
              .filter(item => item.devOnly ? auth.developer : true)
              .map((item, idx) => (
                <ListItem disablePadding key={idx}>
                  <ListItemButton
                    onClick={() => navigate(item.path)}
                    selected={location.pathname === item.path}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                </ListItem>
              ))}
        </List>
      </Box>
      <Box>
      <Button
        variant="contained"
        startIcon={<LogoutIcon />}
        onClick={() => auth.signOut().finally(() => navigate("/login"))}
        color="error"
        sx={{ margin: "10px", width: "90%" }}
      >
        Logout
      </Button>
      </Box>
    </Box>
  );
};

export default Sidebar;