import React, { useEffect, useState } from "react";

import { Alert, Button, TextField } from "@mui/material";

interface Props {
  credentials: API.Credentials;
  onChange: (credentials: API.Credentials) => void;
  onDelete: (credentialsId: string) => void;
}

const CredentialInput: React.FC<Props> = (props) => {
  const [credentials, setCredentials] =
    useState<API.Credentials>({
      id: "",
      url: "",
      username: "",
      password: "",
    });

  useEffect(() => {
    if (props.credentials)
      setCredentials({ ...props.credentials /*, password: ""*/ });
  }, [props.credentials]);

  return (
    <>
      {/* <Alert severity={props.credentials.verified ? "success" : "error"}>
        {props.credentials.verified
          ? "Erfolgreich verbunden"
          : "Bitte Zugangsdaten überprüfen"}
      </Alert> */}
      <TextField
        label="URL"
        value={credentials.url}
        onChange={(e) =>
          setCredentials({ ...credentials, url: e.target.value })
        }
        margin="dense"
        variant="standard"
        fullWidth
      />
      <TextField
        label="Username"
        value={credentials.username}
        onChange={(e) =>
          setCredentials({ ...credentials, username: e.target.value })
        }
        margin="dense"
        variant="standard"
        fullWidth
      />
      <TextField
        label="Password"
        value={credentials.password}
        onChange={(e) =>
          setCredentials({ ...credentials, password: e.target.value })
        }
        margin="dense"
        variant="standard"
        type="password"
        fullWidth
      />
      {credentials.prefix !== undefined && <TextField
        label="Vario-Prefix"
        value={credentials.prefix}
        onChange={(e) =>
          setCredentials({ ...credentials, prefix: e.target.value })
        }
        margin="dense"
        variant="standard"
        fullWidth
      />}
      {credentials.name !== undefined && <TextField
        label="Lieferanten-Name"
        value={credentials.name}
        onChange={(e) =>
          setCredentials({ ...credentials, name: e.target.value })
        }
        margin="dense"
        variant="standard"
        fullWidth
      />}
      <Button onClick={() => props.onChange(credentials)}>Speichern</Button>
      <Button onClick={() => props.onDelete(credentials.id)} color="error">Löschen</Button>
    </>
  );
};

export default CredentialInput;
