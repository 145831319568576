import React, { useEffect, useState } from "react";
import MenuView from "../../MenuView";

import { Box, Tab, Tabs } from "@mui/material";
import { useAuth } from "../../AuthContext";
import AlsoVarioSync from "./Features/AlsoVarioSync/AlsoVarioSync";
import { getCompanyModules } from "../../database";
import VeloVarioSync from "./Features/VeloVarioSync/VeloVarioSync";

type feature = {
  name: string;
  id: string;
  requiredAPI: API.APIS[];
};

const features: feature[] = [
  {
    name: "Also -> Vario",
    id: "AlsoVarioSync",
    requiredAPI: ["ALSO", "VARIO"],
  },
  {
    name: "Velo -> Vario",
    id: "VeloVarioSync",
    requiredAPI: ["VELO", "VARIO"],
  },
];

function Apis() {
  const [activeFeature, setAciveFeature] = useState<string | null>(null);
  const [companyModules, setCompanyModules] = useState<API.CompanyModule[]>([]);

  const auth = useAuth();
  const company = auth.currentDatabaseCompany!;

  useEffect(() => {
    return getCompanyModules(company.id, setCompanyModules);
  }, [company.id]);

  const getAvailableStatus = (apis: API.APIS[]) => {
    for (const api of apis) {
      const module = companyModules.filter((m) => m.id === api)[0];
      if (!module || module.state !== "active") {
        return false;
      }
    }
    return true;
  };

  const filteredFeatures = features.filter((feature) =>
    getAvailableStatus(feature.requiredAPI)
  );

  useEffect(() => {
    if (filteredFeatures.length > 0) setAciveFeature(filteredFeatures[0].id);
  }, [companyModules]);

  let activeFeatureComponent = null;

  switch (activeFeature) {
    case "AlsoVarioSync":
      activeFeatureComponent = <AlsoVarioSync />;
      break;
    case "VeloVarioSync":
      activeFeatureComponent = <VeloVarioSync />;
      break;
  }

  return (
    <MenuView>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeFeature ?? false}
          onChange={(_, val) => setAciveFeature(val)}
        >
          {filteredFeatures.map((feature) => (
            <Tab key={feature.id} value={feature.id} label={feature.name} />
          ))}
        </Tabs>
      </Box>
      {activeFeatureComponent}
    </MenuView>
  );
}

export default Apis;
