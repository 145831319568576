import React, { useEffect, useState } from "react";

import { germanApi } from "../../../misc/misc";
import CustomDialog from "./CustomDialog";
import CredentialInput from "./CredentialInput";
import AddCredentialsButton from "./AddCredentialsButton";
import { deleteCredentials, getCredentials, updateCredentials } from "../../../database";
import { useAuth } from "../../../AuthContext";

interface Props {
  module: API.CompanyModule;
  open: boolean;
  close: () => void;
}

const VeloCredentials: React.FC<Props> = ({ module, open, close }) => {
  const [credentials, setCredentials] = useState<API.Credentials[]>([]);

  const auth = useAuth();
  const company = auth.currentDatabaseCompany!;

  useEffect(() => {
    return getCredentials(company.id, module.id, setCredentials);
  }, [module]);

  const credentialsChangeHandler = (credentials: API.Credentials) => {
    updateCredentials(company.id, module.id, credentials);
  };

  const credentialsDeleteHandler = (credentialsId: string) => {
    deleteCredentials(company.id, module.id, credentialsId);
  };

  return (
    <CustomDialog open={open} close={close} apiName={germanApi(module.id)}>
      {credentials.map((c) => (
        <CredentialInput key={c.id} credentials={c} onChange={credentialsChangeHandler} onDelete={credentialsDeleteHandler}/>
      ))}
      <AddCredentialsButton api={module.id} />
    </CustomDialog>
  );
};

export default VeloCredentials;
