import React, { useEffect, useState } from "react";

import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useNavigate,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./AuthContext";
import Login from "./Login";
import Home from "./pages/Home";
import Apis from "./pages/Apis/Apis";
import Store from "./pages/Store/Store";
import Settings from "./pages/Settings/Settings";
import Logs from "./pages/Logs/Logs";
import {
  AppBar,
  Box,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import Logo from "./misc/images/logo.png";
import AdminPanel from "./admin/AdminPanel";
import View from "./View";
import CompanyEdit from "./admin/CompanyEdit";

const PrivateWrapper = () => {
  const [waitForAuth, setWaitForAuth] = useState(true);

  const auth = useAuth();
  const navigate = useNavigate();

  // check company database entry -> redirect to login if can't request within 5 seconds
  useEffect(() => {
    if (
      auth.currentDatabaseCompany ||
      auth.currentDatabaseUser?.rights === "admin"
    ) {
      setWaitForAuth(false);
    } else {
      const autoLogout = setTimeout(
        () => auth.signOut().finally(() => navigate("/login")),
        5000
      );
      return () => clearTimeout(autoLogout);
    }
  }, [auth.currentDatabaseCompany, auth.currentDatabaseUser]);

  // check firebase user -> redirect to login, if login-state doesn't change within 500ms
  useEffect(() => {
    if (!auth.currentUser) {
      const autoLogout = setTimeout(() => navigate("/login"), 500);
      return () => clearTimeout(autoLogout);
    }
  }, [auth.currentUser]);

  if (waitForAuth)
    return (
      <Box sx={{ textAlign: "center", paddingTop: "100px" }}>
        <CircularProgress />
      </Box>
    );

  if (auth.currentDatabaseUser && auth.currentDatabaseCompany)
    return <Outlet />;

  if (auth.currentDatabaseUser && auth.currentDatabaseUser.rights === "admin")
    return <View><CompanyEdit/></View>;

  return null;
};

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <AppBar sx={{ backgroundColor: "#84BF2C" }}>
          <Stack direction="row">
            <img
              src={Logo}
              alt="QMEDIA"
              style={{ margin: "10px", height: "40px" }}
            />
            <div style={{ flex: 1 }}></div>
            <AdminPanel />
          </Stack>
        </AppBar>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateWrapper />}>
            <Route path="/" element={<Home />} />
            <Route path="/apis" element={<Apis />} />
            <Route path="/store" element={<Store />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/logs" element={<Logs />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
