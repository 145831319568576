import React, { useState } from "react";

import {
  ListItemButton,
  ListItemText,
  Avatar,
  ListItemAvatar,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";

import ApiIcon from "@mui/icons-material/Api";
import SettingsIcon from "@mui/icons-material/Settings";

import { germanState } from "../../misc/misc";
import { changeModuleState } from "../../serverFunctions";
import Confirmation from "../Confirmation";
import ModuleStateIcon from "./ModuleStateIcon";
import { useAuth } from "../../AuthContext";

import { updateCompanyModule } from "../../database";

interface Props {
  module: API.CompanyModule;
  onClick: () => void;
}

const Module: React.FC<Props> = (props) => {
  const auth = useAuth();
  const user = auth.currentDatabaseUser!;
  const company = auth.currentDatabaseCompany!;

  const [loading, setLoading] = useState(false);

  const order = () => {
    const change: UserFunctionsTypes.ModuleStateChange = {
      moduleID: props.module.id,
      newState: "ordered",
    };
    setLoading(true);
    changeModuleState(change).finally(() => {
      setLoading(false);
    });
  };

  const cancel = () => {
    const change: UserFunctionsTypes.ModuleStateChange = {
      moduleID: props.module.id,
      newState: "available",
    };
    setLoading(true);
    changeModuleState(change).finally(() => {
      setLoading(false);
    });
  };

  const setState = (state: API.moduleState) => {
    updateCompanyModule(company.id, { ...props.module, state });
  };

  const getButton = (state: API.moduleState) => {
    if (user.rights === "admin") {
      return (
        <>
          <ModuleStateIcon
            state="disabled"
            currentState={props.module.state}
            setState={setState}
          />
          <ModuleStateIcon
            state="available"
            currentState={props.module.state}
            setState={setState}
          />
          <ModuleStateIcon
            state="ordered"
            currentState={props.module.state}
            setState={setState}
          />
          <ModuleStateIcon
            state="active"
            currentState={props.module.state}
            setState={setState}
            end
          />
        </>
      );
    }

    switch (state) {
      case "available":
        return (
          <Confirmation
            action={order}
            buttonColor="primary"
            buttonDisabled={loading}
            variant="outlined"
          >
            bestellen
          </Confirmation>
        );
      case "ordered":
        return (
          <Confirmation
            action={cancel}
            buttonColor="error"
            buttonDisabled={loading}
          >
            stornieren
          </Confirmation>
        );
      case "active":
        return (
          <Confirmation
            action={cancel}
            buttonColor="error"
            buttonDisabled={loading}
          >
            kündigen
          </Confirmation>
        );
      default:
        return <div></div>;
    }
  };

  const verified = false;

  return (
    <ListItem disablePadding secondaryAction={getButton(props.module.state)}>
      <ListItemButton onClick={props.onClick}>
        <Stack direction="row" spacing={2} alignItems="center">
          <SettingsIcon />
          <ListItemAvatar>
            <Avatar>
              {props.module.logo ? (
                <img src={props.module.logo} alt="" />
              ) : (
                <ApiIcon />
              )}
            </Avatar>
          </ListItemAvatar>
        </Stack>
        <ListItemText
          primary={props.module.name}
          secondary={
            <>
              {germanState(props.module.state)}
              {/*props.module.state === "active" && (
                <>
                  {" - "}
                  <Typography
                    component="span"
                    color={verified ? "primary" : "error"}
                    sx={{ display: "inline-block" }}
                  >
                    {verified
                      ? "Erfolgreich verbunden"
                      : "Fehlerhafte Zugangsdaten"}
                  </Typography>
                </>
                    )*/}
            </>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

export default Module;
