import React from "react";

import { Button } from "@mui/material";

import { useAuth } from "../AuthContext";
import { addCompany } from "../database";
import { useNavigate } from "react-router-dom";

function CompanyEdit() {
  const navigate = useNavigate();
  const auth = useAuth();

  const add = () => {
    addCompany().then((doc) => {
      auth.selectCompany(doc.id);
      navigate("/settings");
    });
  };

  return (
    <>
      <Button onClick={add}>Hinzufügen</Button>
    </>
  );
}

export default CompanyEdit;
