import React, { useState } from 'react';

import { Box, Stack } from "@mui/material";

import Menu from "./Menu";
import View from "./View";
import Sidebar from "./Sidebar";

export const MenuView: React.FC<{}> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
  };
  return (
    <>
    <Box sx={
      (theme) => (
        {
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        } 
      }
      )
    }>
      {/* <button onClick={() => setIsSidebarOpen(true)}>Open Sidebar</button> */}
      <Sidebar open={isSidebarOpen} onClose={handleSidebarClose} />
      <View>
        <Box sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
          {children}
        </Box>
      </View>
    </Box>
    </>
   
  );
};

export default MenuView;
