import React from "react";
import { useAuth } from "../AuthContext";
import MenuView from "../MenuView";

function Home() {
  const auth = useAuth();
  return (
    <MenuView>
      Herzlich Willkommen!
    </MenuView>
  );
}

export default Home;
