import React, { useEffect, useState } from "react";
import { useAuth } from "../../AuthContext";
import { getCompanyModules } from "../../database";
import MenuView from "../../MenuView";
import Module from "./Module";
import { List, ListSubheader, Paper } from "@mui/material";
import { Objects } from "../../misc/objects";
import AlsoCredentials from "./Credentials/AlsoCredentials";
import VarioCredentials from "./Credentials/VarioCredentials";
import VeloCredentials from "./Credentials/VeloCredentials";

function Store() {
  const auth = useAuth();
  const company = auth.currentDatabaseCompany!;
  const user = auth.currentDatabaseUser!;

  const [openModuleId, setOpenModuleId] = useState<API.APIS | null>(null);
  const [companyModules, setCompanyModules] = useState<API.CompanyModule[]>([]);

  const openModule =
    openModuleId && companyModules.filter((m) => m.id === openModuleId)[0];

  const updateCompanyModules = (modules: API.CompanyModule[]) => {
    if (user.rights === "admin") {
      let userModules: API.CompanyModule[] = [];
      for (const staticModule of Objects.availableModules) {
        const companyModule = modules.filter(
          (m) => m.id === staticModule.id
        )[0];
        if (companyModule && companyModule.state !== "disabled") {
          userModules = [
            ...userModules,
            {
              ...staticModule,
              ...companyModule,
            },
          ];
        } else {
          userModules = [
            ...userModules,
            {
              ...staticModule,
              state: "disabled",
            },
          ];
        }
      }
      setCompanyModules(userModules);
    } else {
      setCompanyModules(modules.filter((m) => m.state !== "disabled"));
    }
  };

  useEffect(() => {
    return getCompanyModules(company.id, updateCompanyModules);
  }, [company.id]);

  let dialog = null;

  if (openModule) {
    const dialogProps = {
      module: openModule,
      open: openModule !== null,
      close: () => setOpenModuleId(null),
    };

    switch (openModule.id) {
      case "ALSO":
        dialog = <AlsoCredentials {...dialogProps} />;
        break;
      case "VARIO":
        dialog = <VarioCredentials {...dialogProps} />;
        break;
      case "VELO":
        dialog = <VeloCredentials {...dialogProps} />;
        break;
    }
  }

  return (
    <MenuView>
      <Paper>
        <List sx={{ pt: 1 }} subheader={<ListSubheader>Module</ListSubheader>}>
          {companyModules.map((module) => (
            <Module
              key={module.id}
              module={module}
              onClick={() => setOpenModuleId(module.id)}
            />
          ))}
        </List>
        {dialog}
      </Paper>
    </MenuView>
  );
}

export default Store;
