import React, { useEffect, useState } from "react";

import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { getCompanies } from "../database";

function CompanySelect() {
  const navigate = useNavigate();
  const auth = useAuth();

  const [companies, setCompanies] = useState<API.company[]>([]);

  const handleChange = (event: SelectChangeEvent) => {
    auth.selectCompany(event.target.value)
    if(event.target.value === "")
        navigate("/");
  };

  useEffect(()=>{
    return getCompanies(setCompanies);
  },[])

  return (
      <FormControl variant="standard" sx={{ m: 1, minWidth: 220 }} size="small">
        <InputLabel id="company-select-label">Unternehmen</InputLabel>
        <Select
          labelId="company-select-label"
          id="company-select"
          value={auth.currentDatabaseCompany?.id ?? ""}
          onChange={handleChange}
          label="Unternehmen"
        >
          <MenuItem value="">
            <em>bearbeiten</em>
          </MenuItem>
          {companies.map(company => <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>)}
        </Select>
      </FormControl>

  );
}

export default CompanySelect;



