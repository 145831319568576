import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Alert } from "@mui/material";

interface Props {
  open: boolean;
  action: (email: string, password: string) => void;
  abort: () => void;
  error: string;
  disabled: boolean;
}

const AddUserDialog: React.FC<Props> = ({
  open,
  action,
  abort,
  error,
  disabled,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (open) {
      setPassword(Math.random().toString(36).substr(2, 8));
      setEmail("");
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={abort}>
      <DialogTitle>Benutzer anlegen</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Mit welche Zugangsdaten soll der Benutzer angelegt werden?
        </DialogContentText>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            action(email, password);
          }}
        >
          <TextField
            autoFocus
            margin="dense"
            label="E-Mail-Addresse"
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={disabled}
          />
          <TextField
            margin="dense"
            label="Passwort"
            type="text"
            fullWidth
            variant="standard"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={disabled}
          />
        </form>

        {error && <Alert severity="error">{error}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button onClick={abort} disabled={disabled}>
          abbrechen
        </Button>
        <Button onClick={() => action(email, password)} disabled={disabled}>
          erstellen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserDialog;
