import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../AuthContext";
import { addAsyncTask, getArticles } from "../../../../database";

import {
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import AlsoVarioSyncTableRow from "./AlsoVarioSyncTableRow";

const AlsoVarioSync: React.FC<{}> = () => {
  const auth = useAuth();
  const company = auth.currentDatabaseCompany!;

  const [articles, setArticles] = useState<API.DatabaseArticle[]>([]);
  const [filter, setFilter] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [priceNeeded, setPriceNeeded] = useState(true);

  useEffect(() => {
    return getArticles(company.id, setArticles);
  }, [company.id]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
    setPage(0);
  };

  const handleClickAlsoSync = () => {
    addAsyncTask(company.id,"asyncAlsoProductDownload");
  }

  const handleClickVarioSync = () => {
    addAsyncTask(company.id,"asyncVarioProductUpload");
  }

  const filteredArticles = articles.filter(
    (a) =>
      a.name.toLowerCase().includes(filter.toLowerCase()) ||
      a.alsoId.toString().includes(filter) ||
      a.varioId.toString().includes(filter) ||
      a.varioArticleNumber?.toLowerCase().includes(filter.toLowerCase()) ||
      a.articleNumber.toLowerCase().includes(filter.toLowerCase())
  ).filter((a)=>priceNeeded ? a.priceNetto : true );
  const start = page * rowsPerPage;
  const end = start + rowsPerPage;
  const displayArticles = filteredArticles.slice(start, end);

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2}>
        <TextField
          label="Filter"
          variant="standard"
          value={filter}
          onChange={handleChangeFilter}
          sx={{ mb: 1, flex: 1 }}
        />
        <FormControlLabel control={<Checkbox defaultChecked onChange={(_,checked)=>setPriceNeeded(checked)} />} label="mit Preis" />
        <Button variant="contained" onClick={handleClickAlsoSync}>Produkte von ALSO laden</Button>
        <Button variant="contained" onClick={handleClickVarioSync}>Produkte in VARIO speichern</Button>
      </Stack>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right" width={100}>Preis</TableCell>
              <TableCell align="right">Vario</TableCell>
              <TableCell align="right">Speichern</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayArticles.map((a) => (
              <AlsoVarioSyncTableRow key={a.id} article={a} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredArticles.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default AlsoVarioSync;
